<template>
  <div>
    <Header></Header>
    <div class="content">
      <router-view></router-view>
    </div>
    <Footer></Footer>
  </div>
</template>
<script setup></script>
<style lang="scss" scoped>
.content {
  min-height: 89vh;
  overflow: hidden;
  overflow-y: auto;
  margin: 0 20%;
  background-color: #fff;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(134, 125, 125, 0.6);
}
::-webkit-scrollbar-track-piece {
  background-color: rgb(255, 255, 255);
}
:deep(.el-button .el-button--primary .is-disabled) {
  background-color: #eeeeee;
}
</style>
